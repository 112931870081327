@import "./colours";

.notification-error {
  background-color: $red;
  color: #ffffff;

  .mat-button {
    background-color: #d56763;
    color: #ffffff;
  }
}

.notification-success {
  background-color: #77a248;
  color: #ffffff;

  .mat-button {
    background-color: #93b56d;
    color: #ffffff;
  }
}

.notification-warning {
  background-color: $amber;
  color: #ffffff;

  .mat-button {
    background-color: #eeb463;
    color: #ffffff;
  }
}
