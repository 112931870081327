// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Include non-theme styles for core.
@include mat.core();
// Define your application's custom theme.
$custom-primary: mat.define-palette(mat.$blue-palette);
$custom-accent:  mat.define-palette(mat.$teal-palette, A200, A100, A400);
$custom-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme($custom-primary, $custom-accent, $custom-warn);

// Include theme styles for Angular Material components.
@include mat.all-component-themes($theme);

$alt-primary: mat.define-palette(mat.$indigo-palette);
$alt-accent:  mat.define-palette(mat.$grey-palette, A200, A100, A400);
$alt-warn: mat.define-palette(mat.$red-palette);

$alt-theme: mat.define-dark-theme($alt-primary, $alt-accent, $alt-warn);




// // Set custom background color
// $custom-background-color: map_get($mat-blue-grey, 50);

// $background: map-get($alt-theme, background);
// $background: map_merge($background, (background: $custom-background-color));
// $alt-theme: map_merge($alt-theme, (background: $background));
nav{
    background-color: mat.get-color-from-palette($custom-primary);
}
.alternative {
    @include mat.all-component-themes($alt-theme);
    nav{
        background-color: mat.get-color-from-palette($alt-primary);
    }
    .page-container{
        background-color: #121212;
    }
    h1,h2,h3,h4,h5,h6, hr, text, p{
        color: white;
        border-color: white;
        fill: white;
    }
    .mat-dialog-container, footer{
        background-color: #424242 !important;
    }
    input, .mat-checkbox-label, .mat-button{
        color: white;
    }

    // ngx datatable styling for dark mode
    .ngx-datatable.material {
        .datatable-header {
            .datatable-header-cell {
            background-color: #424242 !important;
            color: white !important;
            }
        }
        .datatable-body{
            .datatable-body-cell {
                color: white !important;
                a:hover{
                    color: #93c7ff
                }
            }
            .empty-row {
                color: white !important;
            }
        }
        .datatable-footer{
            .page-count{
                color: white !important;
            }
            .datatable-pager {
                .datatable-icon-prev, .datatable-icon-left, a, .datatable-icon-right, .datatable-icon-skip{
                    color: white !important;
                }
                li.disabled{
                    .datatable-icon-prev, .datatable-icon-left, a, .datatable-icon-right, .datatable-icon-skip{
                        color:grey !important;
                    }
                }
            }
        }
        background-color: #424242 !important;
    }
    .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
            background-color: #525151 !important;
    }
    .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
        color: white !important;
    }
    .col-lg-4{
        color: white;
    }
    .ngx-charts .grid-panel.odd rect{
        fill: rgba(0,0,0,.05);
    }
    .chart-legend .legend-title-text{
        color: white !important;
    }
}
// // dashboard nav light
// $dash-nav-primary: mat-palette($mat-white);
// $dash-nav-accent:  mat-palette($mat-lightgrey);
// $dash-nav-warn: mat-palette($mat-red);

// $dash-nav-theme: mat-light-theme($dash-nav-primary, $dash-nav-accent, $dash-nav-warn);
// .dashboard-nav {
//     @include angular-material-theme($dash-nav-theme);
// }

// // dashboard nav dark
// $dash-nav-alt-primary: mat-palette($mat-black);
// $dash-nav-alt-accent:  mat-palette($mat-blue);
// $dash-nav-alt-warn: mat-palette($mat-red);

// $dash-nav-theme: mat-light-theme($dash-nav-alt-primary, $dash-nav-alt-accent, $dash-nav-alt-warn);
// .dashboard-nav-alternative {
//     @include angular-material-theme($alt-theme);
// }
