@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "/node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "./assets/styles/colours";
@import "./assets/styles/notifications";

// .datatable-body-row.datatable-row-even {
//   background-color: #e9e9e9 !important;
//   cursor: pointer;
// }
// .datatable-body-row.datatable-row-odd {
//   background-color: #ffffff !important;
//   cursor: pointer;
// }
// .datatable-header {
//   font-weight: bold;
// }
// .datatable-body-row {
//   text-align: left;
// }
// .datatable-body-cell {
//   padding: 10px;
// }
.full-width {
  width: 100%;
}

.page-container {
  margin: 0;
  padding: 1% 1% 1% 1%;
  min-height: 94vh;
}

.btn-datatable {
  margin-top: -5px;
  font-size: 12px;
  float: right;
}

.dialog {
  min-width: 350px;
  max-width: 650px;
  // min-height: 500px;
}
.mat-step-label {
  white-space: normal !important;
}
mat-dialog-container {
  min-width: 350px;
  max-width: 550px;
  // min-height: 500px;
}

.clickable-card {
  cursor: pointer;
}

.inactive-user {
  opacity: 50%;
}

.cdk-overlay-pane {
  max-width: 95vw !important;
}
.row {
  margin: 0 0 10px 0 !important;
}
.mat-card {
  margin-bottom: 10px;
}
.datatable-header-cell {
  user-select: none;
}
.mat-button {
  outline: none !important;
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after {
  color: #6c757d !important;
}
