$green: rgb(90, 174, 65); //#5AAE41
$green-hover: #8fdd77;

$yellow: rgb(251, 176, 64); //#FBB040

$red: #ca433b;
$red-hover: rgb(236, 77, 77);

$amber: #eaa23c;
$amber-hover: rgb(245, 200, 117);
